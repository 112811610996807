
<template>
    <!-- BEGIN: Content-->
    <div class="">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
                            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                <base-image
                                    imgName="forgot-password-v2.svg"
                                    imgGeo="pages"
                                    imgAlt="Login V2"
                                    imgClass="img-fluid"
                                    >
                                </base-image>
                             </div>
                        </div>
                        <!-- /Left Text-->
                        <!--Auth control-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">

                            <!-- sign up-->
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">

                                <base-image
                                    imgName="default_logo.png"
                                    imgGeo="logo"
                                    imgAlt="Sabenzi logo"
                                    :imgDim="[36,null]"
                                    >
                                </base-image>

                                <base-card-response  :cardType=" successStatus[confirmData] " >

                                    <template #header>Confirm account {{ confirmStatus?'success':'failure' }} </template>
                                    <template #default>
                                        <p>
                                            {{ confirmMessage }}
                                        </p>
                                        <p>
                                            <router-link :to=" isAuthenticated?'/dashboard':'/login' ">
                                                <u style="color:#fff"> {{ isAuthenticated?' Go to dashboard ':' Go to login ' }} </u>
                                            </router-link>
                                        </p>
                                    </template>

                                </base-card-response>


                            </div>
                            <!-- End sign up-->




                        </div>
                        <!-- /Auth control -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Content-->
</template>


<script>
import { ref, inject } from 'vue';
import { useStore } from 'vuex';
// @ is an alias to /src

export default {
  name: 'AuthConfirmAccount',
  props: {
      confirmId:{
          type:String,
          default:"nothing"
      }
  },
  setup( props ){
      // get the global vuex store values
      const store = useStore();

      // get the axios object
      const axios = inject('axios');  // inject axios

      //get the urls
      const coreUrl = inject('coreUrl');

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",false);
      store.dispatch("globals/executeSetFooterMenu",false);

      const confirmData = ref("secondary")
      const confirmStatus = ref("")
      const confirmMessage = ref("")

      const successStatus = {
          new_confirmation:"success",
          existing_confirmation:"primary",
          failed_request:"secondary"
      }

      // use axios to get the data
      axios.get(coreUrl.backendUrl+'validate_confirm_account/'+props.confirmId,{withCredentials: true}).then((response) =>{
          // return the full token to the token variable
          confirmData.value = response.data.data;
          confirmStatus.value = response.data.status;
          confirmMessage.value = response.data.message;

      }).catch((error)=>{
          // if an error occours, do this
          console.log(error)
          confirmStatus.value = false;
          confirmMessage.value = "Your request could not be approved at the moment";
      })

      const isAuthenticated = ref(store.getters["auth/getAuthAuthentication"].isAuthenticated);

      return {
          coreUrl,
          isAuthenticated,
          successStatus,
          confirmData,
          confirmStatus,
          confirmMessage
      }


  }

};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.center-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

</style>
